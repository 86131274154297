import { defineMessages } from "react-intl";

export const FbaLotAndExpiryPickerLabels = defineMessages({
  expiryDatePickerNotification: {
    id: "replenishment.fbaV3.lotAndExpiryPicker.notification",
    defaultMessage:
      "This product was not enabled for lot-tracking in Flexport.<br></br>Please select the expiry date for the product (required by Amazon).",
  },
  expiryDatePickerMinDateNotification: {
    id: "replenishment.fbaV3.lotAndExpiryPicker.minDateNotification",
    defaultMessage: "Expiration date must be at least 105 days from today.",
  },
  setLotAndExpiryButtonText: {
    id: "replenishment.fbaV3.lotAndExpiryPicker.setLotAndExpiryButtonText",
    defaultMessage: "Set expiration date",
  },
  setExpiryDateLabel: {
    id: "replenishment.fbaV3.lotAndExpiryPicker.expiryDateLabel",
    defaultMessage: "Set Expiration Date",
  },
  submitButtonText: {
    id: "replenishment.fbaV3.lotAndExpiryPicker.submitButtonText",
    defaultMessage: "Submit",
  },
  cancelButtonText: {
    id: "replenishment.fbaV3.lotAndExpiryPicker.cancelButtonText",
    defaultMessage: "Cancel",
  },
});
