import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import {
  EntityError,
  OperationName,
  OperationStatus,
  OrderStatus,
  SubmitOrderRequest,
} from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { logError } from "Logger";
import { FbaV3Loaders } from "../../commons/FbaV3Constants";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { setOrderConfirmationError, setOrderConfirmationSuccess } from "./OrderConfirmationActions";
import { getFlattenedEntityErrors } from "../../commons/FbaV3Utils";
import { getFbaV3ShipmentShippingPreferences } from "../../transportationOptions/getFbaV3ShipmentShippingPreferences";
import { getReplenishmentOrder } from "transfers/create/actions/replenishmentOrder/getReplenishmentOrder";
import { isNil } from "lodash";

export const getOrderConfirmationStatusAndSubmitFbaOrder =
  (isConfirmationInProgress: boolean): SPThunkAction =>
  async (dispatch, getState): Promise<void> => {
    if (isConfirmationInProgress) {
      return;
    }

    dispatch(addLoader(FbaV3Loaders.GET_CONFIRM_ORDER_STATUS_AND_SUBMIT_ORDER));

    try {
      const { replenishmentOrder } = selectTransferCreate(getState());

      if (isNil(replenishmentOrder)) {
        throw new Error("Replenishment order not found in state");
      }

      const { value: operations } = await replenishmentClient.fetchOperations({
        orderId: replenishmentOrder.orderId,
        operationNames: [OperationName.CONFIRM_PLACEMENT_OPTION_AND_CREATE_ORDER],
      });

      const { operationStatus, errors: operationErrors, id: operationId } = operations[0];

      if ([OperationStatus.IN_PROGRESS, OperationStatus.CREATED].includes(operationStatus)) {
        dispatch(setOrderConfirmationSuccess(operationStatus, operationId));
        return;
      } else if (operationStatus === OperationStatus.FAILED) {
        dispatch(setOrderConfirmationError(getFlattenedEntityErrors(operationErrors), operationStatus, operationId));
        return;
      }

      const order = await getReplenishmentOrder(replenishmentOrder.orderId);

      if (order.orderStatus === OrderStatus.SUBMITTED) {
        dispatch(setOrderConfirmationSuccess(OperationStatus.SUCCESS, undefined));
        return;
      }

      const shipmentShippingPreferences = await dispatch(getFbaV3ShipmentShippingPreferences(order));
      const submitOrderRequest: SubmitOrderRequest = { shipmentShippingPreferences };

      await replenishmentClient.submitOrderV2(order.orderId, submitOrderRequest);
    } catch (err) {
      logError({ fn: "getOrderConfirmationStatusAndSubmitOrder" }, err, "Failed to confirm FBA order");
      const error = err?.response?.data?.error ?? err;

      error instanceof EntityError
        ? dispatch(
            setOrderConfirmationError(
              getFlattenedEntityErrors([error]),
              OperationStatus.FAILED,
              error?.payload?.operationId
            )
          )
        : dispatch(
            setOrderConfirmationError(
              [
                new EntityError({
                  message: error?.message,
                  code: error?.code,
                }),
              ],
              OperationStatus.FAILED,
              undefined
            )
          );
    } finally {
      dispatch(clearLoader(FbaV3Loaders.GET_CONFIRM_ORDER_STATUS_AND_SUBMIT_ORDER));
    }
  };
