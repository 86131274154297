import { FeatureName, useFeatureOn } from "common/Split";
import { SearchOrdersProps, searchOrders } from "../actions/searchOrders";
import { batch, useDispatch, useSelector } from "react-redux";
import { getIsMobile, selectIsZoneBasedMerchant } from "common/user/UserSelectors";
import { isString, toNumber } from "lodash/fp";
import { setCurrentPage, setCurrentPageRoute } from "../actions/setCurrentPage";
import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { OrderPageItem } from "../OrderListReducer";
import { Path } from "paths";
import { RootState } from "RootReducer";
import { clearSearchCache } from "../actions/clearSearchCache";
import { parse } from "qs";
import { removeAllEmTags } from "common/StringUtils";
import { searchOrdersAndImportErrors } from "../actions/searchOrdersAndImportErrors";
import { setCurrentSearchTerm } from "../actions/setCurrentSearchTerm";
import { setOrdersSort } from "../actions/setOrderSort";
import { shouldPreserveListState } from "common/RouteUtils";
import { useMount } from "react-use";

export const useOrderListTable = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const searchInfo = useSelector((state: RootState) => state.orderListV2.searchInfo);
  const currentSearchTerm = useSelector((state: RootState) => state.orderListV2.currentSearchTerm);
  const currentPageItems = useSelector((state: RootState) => state.orderListV2.currentPageItems);
  const sort = useSelector((state: RootState) => state.orderListV2.sort);
  const resultsPerPage = useSelector((state: RootState) => state.user.resultsPerPage.orderList);
  const prevResultsPerPage = useRef(resultsPerPage);
  const isMobile = useSelector(getIsMobile);
  const useOrdersLogisticsSearchV2 = useFeatureOn(FeatureName.LogisticsSearchOrderListUpdatedSearch);
  const isOrdersLogisticsSearchOn = true; // useFeatureOn(FeatureName.LogisticsSearchOrdersReadEnabled);
  const isOrderIngestErrorsLogisticsSearchOn = true; // useFeatureOn(FeatureName.LogisticsSearchChannelEventsReadEnabled);
  const isZoneBasedMerchant = useSelector(selectIsZoneBasedMerchant);

  useMount(() => {
    if (!(shouldPreserveListState(location, history) && searchInfo)) {
      const { page = undefined, search = undefined } = location.search ? parse(location.search.slice(1)) : {};
      const formattedPage: number | undefined = page && (isString(page) ? toNumber(page) : page);

      batch(() => {
        dispatch(clearSearchCache());

        const searchOrderProps: SearchOrdersProps = {
          isOrderIngestErrorsLogisticsSearchOn,
          useOrdersLogisticsSearchV2,
        };
        if (formattedPage) {
          searchOrderProps.page = formattedPage - 1;
          setCurrentPageRoute(formattedPage - 1);
        }
        if (search) {
          dispatch(setCurrentSearchTerm(search));
          dispatch(searchOrdersAndImportErrors(searchOrderProps));
        } else {
          dispatch(searchOrdersAndImportErrors(searchOrderProps));
        }
      });
    }
  });

  useEffect(() => {
    if (resultsPerPage !== prevResultsPerPage.current) {
      prevResultsPerPage.current = resultsPerPage;
      dispatch(searchOrders({ isOrdersLogisticsSearchOn, useOrdersLogisticsSearchV2 }));
    }
  }, [resultsPerPage, prevResultsPerPage, dispatch]);

  const onPageChange = (page: number) => {
    batch(() => {
      dispatch(setCurrentPage(page));
      dispatch(searchOrders({ isOrdersLogisticsSearchOn, useOrdersLogisticsSearchV2 }));
    });
    setCurrentPageRoute(page);
  };

  const itemPath = ({ id, objectID }: OrderPageItem) =>
    Path.orderDetail.replace(":orderId", removeAllEmTags(`${id ?? objectID}`));

  const onItemClick = (item: OrderPageItem) => history.push(itemPath(item));

  const onOrdersSort = (sortBy) =>
    dispatch(setOrdersSort(sortBy, isOrdersLogisticsSearchOn, useOrdersLogisticsSearchV2));

  return {
    onPageChange,
    onItemClick,
    itemPath,
    onOrdersSort,
    currentSearchTerm,
    currentPageItems,
    sort,
    searchInfo,
    isMobile,
    isZoneBasedMerchant,
  };
};
