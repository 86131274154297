export enum ReturnLoader {
  ReturnsCreateLoader = "ReturnsCreateLoader",
  ReturnsLabelLoader = "ReturnsLabelLoader",
  ReturnsListLoader = "ReturnsListLoader",
  ReturnsSellerLoader = "ReturnsSellerLoader",
  ReturnsSellerUpdateAddressLoader = "ReturnsSellerUpdateAddressLoader",
  ReturnsSellerUpdateCarrierLoader = "ReturnsSellerUpdateCarrierLoader",
  ReturnsOnboardLoader = "ReturnsOnboardLoader",
  ReturnsRmtLoader = "ReturnsRmtLoader",
  ReturnsSopLoader = "ReturnsSopLoader",
  ReturnsSellerDispositionUpdateLoader = "ReturnsSellerDispositionUpdateLoader",
  ReturnsSellerImagePreferenceUpdateLoader = "ReturnsSellerImagePreferenceUpdateLoader",
  PackagesListLoader = "PackagesListLoader",
  PackagesDetailLoader = "PackagesDetailLoader",
}
