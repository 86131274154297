import { Box, Text, ThemeProps } from "common/components/ui";
import React, { FC } from "react";
import { getProductSku } from "inbounds/steps/ship/utils/getProductSku";
import { SelectedProductData } from "common/components/ProductChooser";
import { PackInfo } from "common/components/ProductSearch/Packs/components/PackInfo";
import styled from "@emotion/styled";
import { LightDivider } from "./ReplenishmentOrderCreationErrorStyles";

import { RemoveProductButton } from "common/components/ProductChooser/RemoveProductButton";
import { ErrorDescriptionWithTooltip } from "./ReplenishmentOrderError";
import { CustomReplenishmentProductErrorHandlers } from "./custom-product-errors/CustomReplenishmentProductErrorHandlers";

const ReplenishmentProductErrorRow = styled.div(`
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
`);

const ProductDescriptionText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[300]};
    font-size: 13px;
  `
);

interface ProductErrorProps {
  productWithError: {
    product: SelectedProductData;
    productErrorDescription: string;
    errorCode: string;
    rawErrorMessage?: string;
  };
  removeProduct: (dsku: string) => void;
}

export const ReplenishmentProductError: FC<ProductErrorProps> = ({ productWithError, removeProduct }) => {
  const { product, productErrorDescription, errorCode, rawErrorMessage } = productWithError;
  const { name: productName, dsku } = product;

  return (
    <>
      <ReplenishmentProductErrorRow role="row" data-testid="replenishment-product-error-row">
        <Box width={"40%"} paddingRight={"S2"}>
          <Text bold as="span">
            {productName}
          </Text>

          <ProductDescriptionText>{getProductSku(product)}</ProductDescriptionText>

          <ProductDescriptionText>
            <PackInfo product={product} />
          </ProductDescriptionText>
        </Box>

        <Box width={"25%"} paddingLeft={"S2"} paddingRight={"S2"}>
          <ErrorDescriptionWithTooltip
            errorDescription={productErrorDescription}
            rawErrorMessage={rawErrorMessage}
            errorCode={errorCode}
          />
        </Box>

        <Box paddingLeft={"S2"} width={"25%"} paddingRight={"S2"}>
          <CustomReplenishmentProductErrorHandlers errorCode={errorCode} product={product} />
        </Box>

        <RemoveProductButton dsku={dsku} removeProduct={removeProduct} />
      </ReplenishmentProductErrorRow>
      <LightDivider />
    </>
  );
};
