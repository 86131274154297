import { BundleItem, HazmatInformation, ProductAlias } from "@deliverr/commons-clients";
import { CountryCode, LengthUnit, PackagingType, WeightUnit } from "@deliverr/commons-objects";
import { ItemizedPrice, ServiceLevel } from "@deliverr/billing-pricer-client";
import { ListState, ListType, createListReducers, listInitialState } from "common/list";
import { ReducerDictionary, createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";

import { CostEstimatorModalActionTypes } from "common/components/EstimatedCosts/CostEstimatorModalActions";
import { InventoryListActionTypes } from "inventory/list/InventoryListActionTypes";
import { InventoryLocationFilterOptions } from "./InventoryListTableControls/InventoryListFilters/InventoryLocationFilter";
import { SortDir } from "common/models";
import { WalmartSyncActionTypes } from "inventory/list/InventoryListTableControls/walmart-sync/WalmartSyncActions";
import { combineReducers } from "redux";

export type InventoryListTab = "PRODUCTS" | "BUNDLES" | "KITS" | "ELIGIBLE_FOR_REMOVAL";

export interface ProductListItem {
  objectID: string;
  name: string;
  dsku: string;
  msku: string;
  asin?: string;
  weightUnit?: WeightUnit;
  lengthUnit?: LengthUnit;
  feePreview: number;
  onHandQty: number;
  unfilledOrderQty: number;
  unavailableQty: number;
  raw: any;
  estimatedCosts?: ItemizedPrice;
  width?: number;
  length?: number;
  height?: number;
  weight?: number;
  dimSourceType?: string;
  daysLeft?: number;
  unitsSoldLast30Days?: number;
  sellerId?: string;
  errorMessage?: string;
  hazmatInformation?: HazmatInformation;
  inboundUnits?: number;
  sellerShippingSpecification?: {
    sourceUser?: string;
    minimumPackagingType?: PackagingType;
  };
  nonPickableQty?: number;
  inTransferQty?: number;
  barcodes?: string[];
  productAliases: ProductAlias[];
  caseQty?: number;
  storageInTransitToEcomInventory?: number;
  storageToEcomProcessingInventory?: number;
  storageOnHandQty?: number;
  storagePlannedQty?: number;
  storageNonPickableQty?: number;
  storageUnfilledOrderQty?: number;
  storageInTransferQty?: number;
  storageUnavailableQty?: number;
  storageInboundUnits?: number;
  returnsUnavailableQty?: number;
  fbaInventory?: {
    fulfillableQuantity?: number;
    inboundShippedQuantity?: number;
    inboundReceivingQuantity?: number;
    inboundWorkingQuantity?: number;
  };
  wfsInventory?: {
    fulfillableQuantity?: number;
    inboundShippedQuantity?: number;
    inboundReceivingQuantity?: number;
    inboundWorkingQuantity?: number;
  };
  packOf?: string;
  isDefaultPack?: boolean;
  packName?: string;
  isKit?: boolean;
  inventory?: {
    aggregates?: {
      available?: number;
      processing?: number;
      total?: number;
      unavailable?: number;
    };
    breakdown?: {
      DEFAULT?: {
        available?: number;
        order_processing?: number;
        total?: number;
        unavailable?: number;
        in_transit?: number;
      };
      STORAGE?: {
        available?: number;
        order_processing?: number;
        total?: number;
        unavailable?: number;
        in_transit?: number;
      };
    };
    timestamp?: string;
  };
}

export interface BundleListItem {
  bsku: string;
  name: string;
  msku: string;
  sellerId: string;
  bundleItems: BundleItem[];
  unitsSoldLast30Days?: number;
  raw: {
    bsku: string;
  };
}

export interface JobRunsListItem {
  dataEarliestTimestamp: string;
}

interface BundleListState extends ListState<BundleListItem> {
  isPricesLoading: boolean;
  bskuToAvailableQty: { [bsku: string]: number };
  bskuToFulfillmentFee: { [bsku: string]: number | null };
}

export interface ProductListState extends ListState<ProductListItem> {
  showWalmartSync: boolean;
  walmartSyncIsActive?: boolean;
  hasInventory: boolean; // user has one sku with inventory
  isPricesLoading: boolean;
  isStorageInventoryLoading: boolean;
}

export interface InventoryListState {
  searchTerm: string;
  currentTab: InventoryListTab;
  productList: ProductListState;
  bundleList: BundleListState;
  estimatedCostsServiceLevel: ServiceLevel;
  countryCode: CountryCode;
  productSyncInProgress: boolean;
  isActive: boolean;
  filtersBladeOpen: boolean;
  inventoryLocation: InventoryLocationFilterOptions;
  expandedRows: { [index: number]: boolean };
  jobRunsList: ListState<JobRunsListItem>;
}

export const inventoryListInitialFilters = {
  estimatedCostsServiceLevel: ServiceLevel.StandardNonFastTag,
  countryCode: "CA" as CountryCode,
  isActive: true,
};

export const inventoryListInitialState: InventoryListState = {
  ...inventoryListInitialFilters,
  searchTerm: "",
  currentTab: "PRODUCTS",
  productList: {
    ...listInitialState,
    sort: undefined,
    hasInventory: false,
    showWalmartSync: false,
    walmartSyncIsActive: false,
    isPricesLoading: false,
    isStorageInventoryLoading: false,
  },
  bundleList: {
    ...listInitialState,
    sort: {
      fieldName: "unitsSoldLast30Days",
      direction: SortDir.DESC,
    },
    isPricesLoading: false,
    bskuToAvailableQty: {},
    bskuToFulfillmentFee: {},
  },
  productSyncInProgress: false,
  filtersBladeOpen: false,
  isActive: true,
  inventoryLocation: InventoryLocationFilterOptions.ALL_LOCATIONS,
  expandedRows: {},
  jobRunsList: listInitialState,
};

const productListReducer = createReducer<ProductListState>(inventoryListInitialState.productList, {
  ...createListReducers(ListType.Inventory),
  ...handleSimpleReducerUpdates([
    InventoryListActionTypes.ESTIMATE_PRODUCT_LIST_COSTS_LOADING,
    InventoryListActionTypes.ESTIMATE_PRODUCT_LIST_COSTS,
    InventoryListActionTypes.CALCULATE_MULTI_PACK_STORAGE_INVENTORY_LOADING,
    InventoryListActionTypes.CALCULATE_MULTI_PACK_STORAGE_INVENTORY,
    WalmartSyncActionTypes.SHOW_WALMART_SYNC,
    WalmartSyncActionTypes.SET_WALMART_SYNC_IS_ACTIVE,
  ]),
  [CostEstimatorModalActionTypes.ESTIMATE_COSTS_LISTING]: (state, { dsku, dimensions, estimatedCosts }) => ({
    ...state,
    listItems: state.listItems.map((item) => (item.dsku === dsku ? { ...item, ...dimensions, estimatedCosts } : item)),
  }),
});

const bundleListReducer = createReducer<BundleListState>(inventoryListInitialState.bundleList, {
  ...(createListReducers(ListType.Bundle) as ReducerDictionary<BundleListState>),
  ...handleSimpleReducerUpdates([
    InventoryListActionTypes.SET_BUNDLE_LIST_AVAILABLE_QTY,
    InventoryListActionTypes.ESTIMATE_BUNDLE_LIST_COSTS,
    InventoryListActionTypes.ESTIMATE_BUNDLE_LIST_COSTS_LOADING,
    InventoryListActionTypes.ESTIMATE_BUNDLE_LIST_COSTS_COMPLETED,
  ]),
});

const jobRunsListReducer = createReducer<ListState<any>>(listInitialState, {
  ...createListReducers(ListType.JobRuns),
});

const listsReducer = combineReducers({
  productList: productListReducer,
  bundleList: bundleListReducer,
  jobRunsList: jobRunsListReducer,
});

const sharedReducer = createReducer<InventoryListState>(inventoryListInitialState, {
  ...handleSimpleReducerUpdates([
    InventoryListActionTypes.SET_ESTIMATED_COSTS_SERVICE_LEVEL,
    InventoryListActionTypes.SET_INVENTORY_LIST_COUNTRY_CODE,
    InventoryListActionTypes.SET_IS_ACTIVE,
    InventoryListActionTypes.PRODUCT_SYNC,
    InventoryListActionTypes.SET_FILTERS_BLADE_OPEN,
    InventoryListActionTypes.SET_TAB,
    InventoryListActionTypes.SET_SEARCH_TERM,
    InventoryListActionTypes.SET_INVENTORY_LOCATION_FILTER,
  ]),
});

export const inventoryListReducer = (state: InventoryListState = inventoryListInitialState, action) => {
  const intermediateState = {
    ...state,
    ...listsReducer(
      {
        productList: state.productList,
        bundleList: state.bundleList,
        jobRunsList: state.jobRunsList,
      },
      action
    ),
  };
  return sharedReducer(intermediateState, action);
};
