import React, { FC } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { Product } from "@deliverr/commons-clients";
import { Stack, Text } from "../../common/components/ui";
import styled from "@emotion/styled";
import { generatePath, Link } from "react-router-dom";
import { Path } from "../../paths";
import { ReturnsPackageQcItem } from "./store";
import Table, { TableProps } from "common/components/lists/Table";
import { ReturnsPackageDetailsEmptyBox } from "./ReturnsPackageDetailsEmptyBox";
import { returnsPackageDetailsMessages } from "./ReturnsPackageDetailsMessages";

const StyledTable = styled(Table)<TableProps<ReturnsPackageQcItem>>(`
    width: 100%;
    min-width:1000px;
`);

const UNKNOWN_ITEM = "UNKNOWN_ITEM";

interface ReturnsPackageItemDetailsProps {
  returnsPackageQcItems: ReturnsPackageQcItem[];
  returnsPackageQcItemDetails: { [dsku: string]: Product };
}

export const ReturnsPackageItemDetails: FC<ReturnsPackageItemDetailsProps> = ({
  returnsPackageQcItems,
  returnsPackageQcItemDetails,
}) => {
  const { formatMessage } = useIntl();

  const getDisplayName = (dsku: string) => {
    if (dsku === UNKNOWN_ITEM) {
      return <Text bold>{formatMessage(returnsPackageDetailsMessages.unknownProduct)}</Text>;
    }
    if (returnsPackageQcItemDetails[dsku]?.name) {
      return <Link to={generatePath(Path.inventoryDetail, { dsku })}>{returnsPackageQcItemDetails[dsku].name}</Link>;
    }
    return <Text bold>{dsku}</Text>;
  };

  return (
    <StyledTable
      hidePaginate={true}
      page="returnsPackagesDetailItems"
      columns={[
        {
          width: "50%",
          renderCell: (item: ReturnsPackageQcItem) => (
            <Stack gap={"S1"}>
              {getDisplayName(item.dsku)}
              <Text>{returnsPackageQcItemDetails[item.dsku]?.msku ?? item.dsku}</Text>
            </Stack>
          ),
          header: <FormattedMessage {...returnsPackageDetailsMessages.productName} />,
        },
        {
          width: "25%",
          renderCell: (item: ReturnsPackageQcItem) => <Text>{item.quantity}</Text>,
          header: <FormattedMessage {...returnsPackageDetailsMessages.quantity} />,
        },
        {
          width: "25%",
          renderCell: (item: ReturnsPackageQcItem) => (
            <>
              {item.returnIds.map((returnId, idx) => (
                <Stack gap={"S1"} key={`${returnId}-${idx}-orderId`}>
                  <Link to={generatePath(Path.returnsDetailWithId, { returnId: String(returnId) })}>{returnId}</Link>
                </Stack>
              ))}
            </>
          ),
          header: <FormattedMessage {...returnsPackageDetailsMessages.returnOrders} />,
        },
      ]}
      items={returnsPackageQcItems}
      noResults={<ReturnsPackageDetailsEmptyBox />}
      allowRowSelection={false}
      className="table"
      onPageChange={() => {}}
    />
  );
};
