import { AttachmentType, ContentType, ShippingPartner } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { uploadFile } from "transfers/utils/uploadFile";
import { logError, logStart } from "Logger";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { transferCreateSetAttachment } from "transfers/create/actions";
import { AttachmentData } from "./AttachmentData";
import { WholesaleShipmentType } from "@deliverr/wholesale-client";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { isInternationalDestination } from "../../utils/isInternationalDestination";
import { isEmpty } from "lodash";

export interface UseReplenishmentShippingDetailsLabelsProps {
  setIsUploadingLabel: Dispatch<SetStateAction<Partial<{ [key in AttachmentType]: boolean }>>>;
  isUploadingLabel: Partial<{ [key in AttachmentType]: boolean }>;
}

export const useReplenishmentShippingDetailsLabels = ({
  setIsUploadingLabel,
  isUploadingLabel,
}: UseReplenishmentShippingDetailsLabelsProps) => {
  const {
    replenishmentOrder,
    attachments = {},
    shippingMethod,
    cargoType,
    destinationAddress,
    destinationType,
  } = useSelector(selectTransferCreate);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const shipmentId = replenishmentOrder?.shipments?.[0].shipmentId;
  const isWholesaleOrder = destinationType === TransferCreationTypes.Wholesale;

  const boxLabelFilename = attachments?.[AttachmentType.CASE_LABELS]?.fileName;
  const palletLabelFilename = attachments?.[AttachmentType.PALLET_LABELS]?.fileName;
  const shippingLabelFilename = attachments?.[AttachmentType.SHIPPING_LABELS]?.fileName;
  const commercialInvoiceFileName = attachments?.[AttachmentType.COMMERCIAL_INVOICE]?.fileName;
  const isSellerCaseLabel = shippingMethod === ShippingPartner.SELLER && cargoType === WholesaleShipmentType.PARCEL;
  const destinationCountry = destinationAddress?.country ?? "";
  const isCommercialInvoiceRequired = isInternationalDestination(destinationCountry);

  const onUploadLabel = useCallback(
    async (file: File, attachmentType: AttachmentType, updatedAttachments: AttachmentData) => {
      setIsUploadingLabel((state) => ({
        ...state,
        [attachmentType]: true,
      }));

      const attachmentDetails = { ...updatedAttachments[attachmentType], attachmentType };
      const isDuplicateFile = Object.values(updatedAttachments).some(
        (attachment) => attachment?.fileName === file.name
      );

      if (isDuplicateFile) {
        toast.error(
          formatMessage({
            id: "replenishment.create.attachmentType.duplicateFile",
            defaultMessage: "File with this name already uploaded.",
          })
        );
        setIsUploadingLabel((state) => ({
          ...state,
          [attachmentType]: false,
        }));
        return;
      }

      try {
        if (isEmpty(attachmentDetails.url)) {
          if (!shipmentId) {
            throw new Error("Shipment ID is missing");
          }
          const { value: uploadShipmentAttachmentRequest } =
            await replenishmentClient.requestShipmentAttachmentUploadUrl(shipmentId, {
              attachmentType,
              contentType: ContentType.PDF,
            });
          attachmentDetails.url = uploadShipmentAttachmentRequest.url;
        }

        await uploadFile(attachmentDetails?.url ?? "", file);
        attachmentDetails.isUploadedToS3 = true;
        attachmentDetails.fileName = file.name;
        dispatch(
          transferCreateSetAttachment({
            [attachmentType]: attachmentDetails,
          })
        );
      } catch (e) {
        logError({ fn: "useReplenishmentShippingDetailsStep.onUploadLabel" }, e);
        toast.error(
          formatMessage({
            id: "replenishment.create.attachmentType.uploadError",
            defaultMessage: "Failed to upload file.",
          })
        );
      }

      setIsUploadingLabel((state) => ({
        ...state,
        [attachmentType]: false,
      }));
    },
    [dispatch, formatMessage, setIsUploadingLabel]
  );

  const onFileClear = useCallback(
    async (attachmentType: AttachmentType, updatedAttachments: AttachmentData) => {
      const ctx = logStart({ fn: "useReplenishmentShippingDetailsStep.onFileClear" });

      const attachmentDetails = { ...updatedAttachments[attachmentType], attachmentType };

      if (shipmentId) {
        setIsUploadingLabel((state) => ({
          ...state,
          [attachmentType]: true,
        }));
        try {
          await replenishmentClient.deleteShipmentAttachment(shipmentId, attachmentType);
          const { value: uploadShipmentAttachmentRequest } =
            await replenishmentClient.requestShipmentAttachmentUploadUrl(shipmentId, {
              attachmentType,
              contentType: ContentType.PDF,
            });

          attachmentDetails.isUploadedToS3 = false;
          attachmentDetails.fileName = undefined;
          attachmentDetails.url = uploadShipmentAttachmentRequest.url;
          dispatch(transferCreateSetAttachment({ [attachmentType]: attachmentDetails }));
        } catch (e) {
          logError(ctx, e);
          toast.error(
            formatMessage({
              id: "replenishment.create.attachmentType.deletionError",
              defaultMessage: "Failed to delete file.",
            })
          );
        } finally {
          setIsUploadingLabel((state) => ({
            ...state,
            [attachmentType]: false,
          }));
        }
      }
    },
    [dispatch, formatMessage, setIsUploadingLabel]
  );

  const onPalletFileChange = useCallback(
    async (files: File[]) => await onUploadLabel(files[0], AttachmentType.PALLET_LABELS, attachments),
    [attachments, onUploadLabel]
  );

  const onShippingFileChange = useCallback(
    async (files: File[]) => await onUploadLabel(files[0], AttachmentType.SHIPPING_LABELS, attachments),
    [attachments, onUploadLabel]
  );

  const onCommercialInvoiceFileChange = useCallback(
    async (files: File[]) => await onUploadLabel(files[0], AttachmentType.COMMERCIAL_INVOICE, attachments),
    [attachments, onUploadLabel]
  );

  const onBoxFileChange = useCallback(
    async (files: File[]) => await onUploadLabel(files[0], AttachmentType.CASE_LABELS, attachments),
    [attachments, onUploadLabel]
  );

  const onPalletFileClear = useCallback(
    async () => await onFileClear(AttachmentType.PALLET_LABELS, attachments),
    [attachments, onFileClear]
  );

  const onShippingFileClear = useCallback(
    async () => await onFileClear(AttachmentType.SHIPPING_LABELS, attachments),
    [attachments, onFileClear]
  );

  const onBoxFileClear = useCallback(
    async () => await onFileClear(AttachmentType.CASE_LABELS, attachments),
    [attachments, onFileClear]
  );

  const onCommercialInvoiceFileClear = useCallback(
    async () => await onFileClear(AttachmentType.COMMERCIAL_INVOICE, attachments),
    [attachments, onFileClear]
  );

  return {
    isSellerCaseLabel,
    isUploadingLabel,
    attachments,
    boxLabelFilename,
    palletLabelFilename,
    shippingMethod,
    onPalletFileChange,
    onBoxFileChange,
    onPalletFileClear,
    onBoxFileClear,
    onShippingFileChange,
    onShippingFileClear,
    shippingLabelFilename,
    isCommercialInvoiceRequired,
    onCommercialInvoiceFileChange,
    onCommercialInvoiceFileClear,
    commercialInvoiceFileName,
    isWholesaleOrder,
  };
};
