import { AddReceivingInfoFormRenderer } from "inbounds/steps/ship/view/cards/confirmation/LtlExternalConfirmation/ReceivingInfo/AddReceivingInfoForm";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSendAndTrackShipmentDetail } from "./useSendAndTrackShipmentDetail";
import styled from "@emotion/styled";
import { Box, ThemeProps, Text, BoxReset } from "common/components/ui";
import { DatePicker } from "common/components/DatePicker";
import { DayOfWeekAndMonth } from "common/date/DateFormat";
import { SpacedText } from "inbounds/steps/ship/view/cards/confirmation/sharedComponents";
import { CarrierSelect } from "common/components/CarrierSelect";

const StyledCarrierSelect = styled(CarrierSelect)<ThemeProps>(
  ({ theme }) => `
  &.selector {
  height: ${theme.spacing.S7};
  margin-bottom: ${theme.spacing.S2};
  margin-top: ${theme.spacing.S1};
}

.Select-control {
  height: ${theme.spacing.S7};
  border: ${theme.border.width.B1} ${theme.colors.NEUTRAL[80]} ${theme.border.type};
  border-radius: ${theme.border.radius.R2};
}

.Select-placeholder {
  color: ${theme.colors.NEUTRAL[300]};
}
`
);

const StyledDatePicker = styled(DatePicker)<ThemeProps>(
  ({ theme }) => `
  &.overrideDatePicker {
  border: ${theme.border.width.B1} ${theme.colors.NEUTRAL[80]} ${theme.border.type};
  border-radius: ${theme.border.radius.R2};
  height: ${theme.spacing.S7};
  margin-bottom: ${theme.spacing.S2};
}

> span {
  color: ${theme.colors.NEUTRAL[300]};
  font-size: ${theme.font.size.F2};
}
`
);

export const SendAndTrackShipment: FC = () => {
  const {
    bolId,
    setBolId,
    proId,
    setProId,
    bolIdPlaceholder,
    proIdPlaceholder,
    submitDisabled,
    onSubmit,
    date,
    onDateChange,
    minDate,
    isFlexport,
    submitButtonMessage,
    ...carrierSelectProps
  } = useSendAndTrackShipmentDetail();

  const newCarrierSelectProps = {
    ...carrierSelectProps,
    className: "selector",
  };

  const carrierInputMarkup = (
    <Box marginBottom={"S3"}>
      <BoxReset marginBottom="S2">
        <Text size="label">
          <FormattedMessage
            id="storage.inbounds.details.sendAndTrackShipment.inputs.carrier"
            defaultMessage="Carrier"
          />
        </Text>
      </BoxReset>
      <StyledCarrierSelect {...newCarrierSelectProps} />
    </Box>
  );

  const shipDateInputMarkUp = (
    <Box marginBottom={"S3"}>
      <BoxReset marginBottom="S2">
        <Text size="label">
          <FormattedMessage
            id="storage.inbounds.details.sendAndTrackShipment.inputs.shipDate"
            defaultMessage="Ship Date"
          />
        </Text>
      </BoxReset>
      <StyledDatePicker
        hideRemoveButton={true}
        format={DayOfWeekAndMonth}
        date={date}
        minDate={minDate}
        onDateChange={onDateChange}
        className={"overrideDatePicker"}
        nextYearButton={null}
        prevYearButton={null}
      />
    </Box>
  );

  const title = (
    <SpacedText>
      <FormattedMessage
        id="storage.inbounds.details.sendAndTrackShipment.description"
        defaultMessage={"Providing tracking information helps ensure your shipment is received on time."}
      />
    </SpacedText>
  );

  const props = {
    isFlexport,
    bolId,
    setBolId,
    proId,
    setProId,
    bolIdPlaceholder,
    proIdPlaceholder,
    submitDisabled,
    onSubmit,
    carrierInputMarkup,
    shipDateInputMarkUp,
    shouldHideHeader: true,
    titleOverride: title,
    submitButtonMessage,
  };

  return <AddReceivingInfoFormRenderer {...props} />;
};
