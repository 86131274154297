import { add } from "date-fns";
import { isObject } from "lodash/fp";
import log from "Logger";

export enum LocalStorageKey {
  CoverageOption = "DSKU_COVERAGE",
  WarehouseToCoordinates = "warehouseToCoordinates",
  FulfillmentFeeInventoryDetail = "FulfillmentFeeInventoryDetail",
  FulfillmentFeeInventoryList = "FulfillmentFeeInventoryList",
  FulfillmentFeeInventoryListSort = "FulfillmentFeeInventoryListSort",
  FulfillmentFeeSelectInboundProducts = "FulfillmentFeeSelectInboundProducts",
  OnboardingChannel = "OnboardingChannel",
  HasMultichannelsStorageId = "HasMultichannelsStorageId",
  IntegrationSetupContext = "IntegrationSetupContext",
  BoxContentsPreferences = "boxContentsPreferences",
  PalletLabelPreferences = "palletLabelPreferences",
  FlexportPlusPopUpModalDoNotShowAgain = "flexportPlusPopUpModalDoNotShowAgain",
  FlexportCapitalBannerDoNotShowAgain = "flexportCapitalBannerDoNotShowAgain",
  FlexportOnboardingCompletedModalDoNotShowAgain = "flexportOnboardingCompletedModalDoNotShowAgain",
  FlexportIntlFreightBannerDoNotShowAgain = "flexportIntlFreightBannerDoNotShowAgain",
  FlexportPrepRequestBannerDoNotShowAgain = "flexportPrepRequestBannerDoNotShowAgain",
  NewFulfillmentRatesBannerDisplayed = "newFulfillmentRatesBannerDisplayed",
  WarehouseMoveBannerDisplayed = "warehouseMoveBannerDisplayed",
  KittingPrepLaunchBannerClosed = "KittingPrepLaunchBannerClosed",
  NewInventoryReportsNotificationDisplayedInReports = "newInventoryReportsNotificationDisplayedInReports",
  NewInventoryReportsNotificationDisplayedInInventoryDetail = "newInventoryReportsNotificationDisplayedInInventoryDetail",
  PeakSeason2024BannerDisplayed = "peakSeason2024BannerDisplayed",
  PeakSeason2024BillingBannerDisplayed = "peakSeason2024BillingBannerDisplayed",
  PeakSeason2024EcommBillingBannerDisplayed = "peakSeason2024EcommBillingBannerDisplayed",
  PriceRefresh2025BillingBannerDisplayed = "priceRefresh2025BillingBannerDisplayed",
  HourlyInventoryUIBannerDisplayed = "hourlyInventoryUIBannerDisplayed",
}

export const getItemFromLocalStorageAndParse = <T>(key: string): T | undefined => {
  try {
    const serializedItem = localStorage.getItem(key);
    if (serializedItem) {
      const parsedItem = JSON.parse(serializedItem);
      if (!isObject(parsedItem)) {
        return parsedItem;
      }

      if (!parsedItem.expiry) {
        return parsedItem.item ?? parsedItem;
      }

      if (Date.now() < parsedItem.expiry) {
        return parsedItem.item;
      }
    }
  } catch (err) {
    log.error({ fn: "getItemFromLocalStorageAndParse", err }, "error deserializing item");
  }
  return;
};

export const setItemInLocalStorage = (key: string, item: any, expiryDays?: number): void => {
  try {
    localStorage.setItem(
      key,
      JSON.stringify({
        item,
        expiry: expiryDays ? add(new Date(), { days: expiryDays }).valueOf() : undefined,
      })
    );
  } catch (err) {
    log.error({ fn: "setItemInLocalStorage", err }, "error setting item in storage");
  }
};

export const maybeGet = async <T>(
  key: string,
  getItem: () => Promise<T | undefined>,
  expiryDays?: number
): Promise<T | undefined> => {
  const savedItem = getItemFromLocalStorageAndParse<T>(key);
  if (savedItem) {
    return savedItem;
  }

  const item = await getItem();
  if (item) {
    setItemInLocalStorage(key, item, expiryDays);
  }
  return item;
};
