import React, { FC } from "react";
import { DefaultTheme, Text } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { FbaLotAndExpiryPickerModal } from "./FbaLotAndExpiryPickerModal";
import { FbaLotAndExpiryPickerLabels } from "./FbaLotAndExpiryPickerLabels";
import { BorderlessButton } from "common/components/BorderlessButton";
import { getFbaLotAndExpiryPickerModalIdForSku } from "../commons/FbaV3Constants";
import { useModal } from "common/hooks/useModal";

const StyledButton = styled(BorderlessButton)<{}, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors[theme.config.colorByAppearance.PRIMARY][300]};

    &:hover {
      color: #11bfe3;
    }
  `
);

interface FbaLotAndExpiryPickerProps {
  dsku: string;
}

export const FbaLotAndExpiryPicker: FC<FbaLotAndExpiryPickerProps> = ({ dsku }) => {
  const { showModal, hideModal } = useModal(getFbaLotAndExpiryPickerModalIdForSku(dsku));

  return (
    <>
      <FbaLotAndExpiryPickerModal dsku={dsku} onCloseModal={hideModal} />
      <StyledButton onClick={showModal}>
        <Text bold>
          <FormattedMessage {...FbaLotAndExpiryPickerLabels.setLotAndExpiryButtonText} />
        </Text>
      </StyledButton>
    </>
  );
};
