import { BaseEventLog } from "@deliverr/commons-clients/lib/event-log/model/BaseEventLog";
import { DeliverrOrder } from "common/clients/orders/DeliverrOrder/DeliverrOrder";
import { OrderError } from "common/clients/orders/DeliverrOrder/OrderError";
import { OrderStatus } from "common/clients/orders/DeliverrOrder/OrderStatus";
import { DeliverrOrderData } from "common/clients/orders/DeliverrOrderData/DeliverrOrderData";
import { DeliverrOrderDetails } from "common/clients/orders/DeliverrOrderDetails/DeliverrOrderDetails";
import { ServiceLevel } from "@deliverr/billing-pricer-client";
import { SearchResults } from "common/search/SearchService";
import { SortDir } from "common/models";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import produce from "immer";
import { RemovalMethods } from "order/new/ShippingMethods";
import { OrderListActionTypes } from "./OrderListActionTypes";

export type OrderListStatusFilter =
  | "ALL_STATUS"
  | "LATE"
  | "IN_TRANSIT"
  | OrderStatus.CREATED
  | OrderStatus.CANCELLED
  | OrderStatus.DELIVERED
  | OrderError.BAD_ADDRESS
  | OrderError.STOCKOUT
  | OrderError.DSKU_NEVER_INBOUNDED;

export type OrderListSummaryFilter =
  | "ALL_SUMMARY"
  | "LATE"
  | OrderStatus.CANCELLED
  | OrderError.BAD_ADDRESS
  | "IMPORT_ERRORS";

export type OrderListServiceFilter = "ALL_SERVICE" | ServiceLevel | RemovalMethods | ServiceLevel[];

export type OrderPageItem = DeliverrOrder &
  DeliverrOrderData & {
    originalOrderCreationTimeUnix: number;
    serviceLevel: ServiceLevel | RemovalMethods;
    raw: {
      id: number;
      marketplaceOrderId: string;
    };
    orderDetails?: DeliverrOrderDetails;
    objectID?: string;
  };

export type OrderImportErrorItem = BaseEventLog & {
  displayAttributes: BaseEventLog["attributes"];
};

export type OrderListDateFilter = "ALL_DATE" | "OLDER_THAN_ONE_WEEK" | "LAST_MONTH";

interface ListSearchState<ItemType extends Record<string, any>> {
  currentPageItems: ItemType[];
  searchInfo?: {
    totalHits: number;
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
  };
}

export interface OrderListState {
  currentSearchTerm: string;
  currentPageItems: OrderPageItem[];
  searchInfo?: {
    totalHits: number;
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
  };
  intlOrders: {
    hits: SearchResults["hits"];
  };
  sort: {
    fieldName: string;
    direction: SortDir;
  };
  filters: {
    status: OrderListStatusFilter;
    serviceLevel: OrderListServiceFilter;
    date: OrderListDateFilter;
    summary: OrderListSummaryFilter;
  };
  searchOrderPreview?: {
    hits: SearchResults["hits"];
    response: SearchResults["response"];
  };
  importErrors: ListSearchState<OrderImportErrorItem>;
}

export const orderListInitialState: OrderListState = {
  currentPageItems: [],
  currentSearchTerm: "",
  sort: {
    fieldName: "originalOrderCreationTimeUnix",
    direction: SortDir.DESC,
  },
  filters: {
    status: "ALL_STATUS",
    serviceLevel: "ALL_SERVICE",
    date: "ALL_DATE",
    summary: "ALL_SUMMARY",
  },
  importErrors: {
    currentPageItems: [],
  },
  intlOrders: {
    hits: [],
  },
};

const STATUS_SUMMARY_INTERSECTION = [OrderStatus.CANCELLED, OrderError.BAD_ADDRESS, "LATE"];

export const reducers: ReducerDictionary<OrderListState> = {
  ...handleSimpleReducerUpdates([
    OrderListActionTypes.SET_CURRENT_SEARCH_TERM,
    OrderListActionTypes.SEARCH_ORDERS_SUCCESS,
    OrderListActionTypes.SEARCH_ORDERS_PREVIEW,
    OrderListActionTypes.SET_ORDERS_SORT,
    OrderListActionTypes.SEARCH_IMPORT_ERRORS_SUCCESS,
  ]),
  [OrderListActionTypes.SET_CURRENT_PAGE]: (state, { page }) =>
    produce(state, (draft) => {
      if (draft.searchInfo) {
        draft.searchInfo.currentPage = page;
      }
    }),
  [OrderListActionTypes.SET_SUMMARY_FILTER]: (state, { summary }) =>
    produce(state, (draft) => {
      draft.filters.summary = summary;
      if (STATUS_SUMMARY_INTERSECTION.includes(summary)) {
        draft.filters.status = summary;
      } else {
        draft.filters.status = "ALL_STATUS";
      }
    }),
  [OrderListActionTypes.SET_ORDERS_FILTER]: (state, { status, serviceLevel, date }) =>
    produce(state, (draft) => {
      draft.filters.status = status;
      draft.filters.serviceLevel = serviceLevel;
      draft.filters.date = date;
      if (STATUS_SUMMARY_INTERSECTION.includes(status)) {
        draft.filters.summary = status;
      } else {
        draft.filters.summary = "ALL_SUMMARY";
      }
    }),
  [OrderListActionTypes.CLEAR_FILTER_PREVIEW]: (state) =>
    produce(state, (draft) => {
      draft.searchOrderPreview = undefined;
    }),
  [OrderListActionTypes.RESET_IMPORT_ERRORS]: (state) =>
    produce(state, (draft) => {
      draft.importErrors = orderListInitialState.importErrors;
    }),
};

export const orderListReducer = createReducer<OrderListState>(orderListInitialState, reducers);
