import { Box, Button, Input, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import React, { ChangeEvent } from "react";
import { FormattedMessage } from "react-intl";
import { useUserScopeMapping } from "common/user/useUserScopes";
import { TOOLTIP_MESSAGES } from "common/utils/tooltipMessages/tooltipMessages";

const CarrierEmailInputContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  text-align: left;
  margin-bottom: ${theme.spacing.S5}
  `
);

export interface CarrierEmailInputProps {
  onSubmit: () => void;
  showAppointmentEmailInputScreen: boolean;
  carrierEmail: string;
  notifyCarrierDisabled: boolean;
  emailInputPlaceHolder: string;
  onCarrierEmailChange: (inputEmail: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: () => void;
  isError: boolean;
  handleFocus: () => void;
}

export const CarrierEmailInputScreen: React.FC<CarrierEmailInputProps> = ({
  onSubmit,
  carrierEmail,
  notifyCarrierDisabled,
  emailInputPlaceHolder,
  onCarrierEmailChange,
  handleBlur,
  isError,
  handleFocus,
}) => {
  const userScopesMapping = useUserScopeMapping();
  const hasInboundsWriteAccess = userScopesMapping.INBOUNDS?.write;
  return (
    <Box marginBottom={"S6"}>
      <FormattedMessage
        id="storage.inbounds.detail.palletAppointmentInstructions.carrierScheduleAppointmentMessage"
        defaultMessage="Provide your carrier’s email address to let them request a delivery appointment directly with the Flexport facility."
      />
      <Box marginBottom={"S5"}>
        <FormattedMessage
          id="storage.inbounds.detail.palletAppointmentInstructions.turnedAwayWarning"
          defaultMessage='Without an appointment, your shipment will be turned away. Please note: trailers should be able to unload to docks 48" high.'
        />
      </Box>
      <CarrierEmailInputContainer>
        <Input
          label={
            <FormattedMessage id="storage.inbounds.detail.emailInput.carrier" defaultMessage="Carrier email address" />
          }
          style={{ width: "50%" }}
          data-testid="storage-carrier-email-input"
          type="email"
          value={carrierEmail}
          onChange={onCarrierEmailChange}
          placeholder={emailInputPlaceHolder}
          onBlur={handleBlur}
          onFocus={handleFocus}
          hasError={isError}
          errorMessage={
            <FormattedMessage id="storage.inbounds.detail.emailInput.invalidMessage" defaultMessage="Invalid email" />
          }
        />
      </CarrierEmailInputContainer>
      <Button
        data-testid="storage-carrier-email-notify-button"
        disabled={notifyCarrierDisabled || !hasInboundsWriteAccess}
        secondary
        onClick={onSubmit}
        tooltipMessage={!hasInboundsWriteAccess ? TOOLTIP_MESSAGES.insufficientAccess : undefined}
      >
        <FormattedMessage id={"storage.carrierEmail.notifyButton"} defaultMessage={"Notify your carrier"} />
      </Button>
    </Box>
  );
};
