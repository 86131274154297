import { getLoadedShipment } from "inbounds/store/selectors/shipments/getLoadedShipment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "RootReducer";
import React from "react";
import isEmail from "validator/lib/isEmail";
import { getCrossDockWarehouse, getInboundIsShipToOne, getLoadedAsns } from "inbounds/steps/InboundStepSelectors";
import { isCompletedShipmentStatus } from "inbounds/ShipmentStatus";
import { getClosestWeekdayDate } from "common/date/getClosestWeekdayDate";
import { useInboundFreightTrackingInfo } from "inbounds/steps/ship/view/cards/useInboundFreightTrackingInfo";
import { setCarrierEmailAction } from "inbounds/InboundActions";

export const usePalletAppointmentInstructions = () => {
  const dispatch = useDispatch();

  const { estimatedShipDate, shippingProvider, hasConfirmedAppointment, updateInboundFreightTrackingInfo } =
    useInboundFreightTrackingInfo();

  const {
    id: shipmentId,
    warehouseId,
    status,
    createdAt,
    carrierEmail: existingCarrierEmail,
    dataDocksLink: warehouseDataDocksLink,
  } = useSelector(getLoadedShipment);

  const warehouses = useSelector((state: RootState) => state.deliverr.warehouses);
  const asns = useSelector(getLoadedAsns);
  const crossDockAsn = useSelector((state: RootState) => state.inbound.crossDockAsn);

  const [isCarrierEmailValid, setIsCarrierEmailValid] = React.useState<boolean>(true);
  const [hasCarrierEmailError, setHasCarrierEmailError] = React.useState<boolean>(false);
  const [carrierEmail, setCarrierEmail] = React.useState<string>(existingCarrierEmail ?? "");

  const cdWarehouse = useSelector(getCrossDockWarehouse);
  const isShipToOne = useSelector(getInboundIsShipToOne);

  const isShipmentCompleted = isCompletedShipmentStatus(status);

  const asnId: number | undefined = isShipToOne
    ? crossDockAsn?.id
    : asns?.find((asn) => asn.shipmentId === shipmentId)?.id;

  const warehouse = isShipToOne ? cdWarehouse : warehouses[warehouseId];

  // If a Shipment is completed, allow the seller to set a previous date.
  const minDate = !isShipmentCompleted ? getClosestWeekdayDate() : new Date(createdAt);

  const onDateChange = (date: Date) => {
    updateInboundFreightTrackingInfo({ estimatedShipDate: date });
  };

  const handleCarrierSelectionChange = (option: string) => {
    updateInboundFreightTrackingInfo({ shippingProvider: option ?? "" });
  };

  // Only used during setup
  const handleToggleAppointmentConfirmed = (event: React.MouseEvent, checked: boolean) => {
    updateInboundFreightTrackingInfo({ hasConfirmedAppointment: checked });
  };

  const updateCarrierEmailInfoToStore = () => {
    dispatch(setCarrierEmailAction(shipmentId, carrierEmail));
  };

  const validateEmail = (inputEmail: string) => {
    setIsCarrierEmailValid(isEmail(inputEmail));
  };

  const handleCarrierEmailBlur = () => {
    if (!isCarrierEmailValid) {
      setHasCarrierEmailError(true);
    } else {
      setHasCarrierEmailError(false);
      updateCarrierEmailInfoToStore();
    }
  };

  const handlCarrierEmailFocus = () => {
    setHasCarrierEmailError(false);
  };

  const onCarrierEmailChange = (e) => {
    setCarrierEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const checkAndSetCarrierEmailError = () => {
    if (!isCarrierEmailValid) {
      setHasCarrierEmailError(true);
    } else {
      setHasCarrierEmailError(false);
    }
  };

  return {
    minDate,
    estimatedShipDate,
    asnId,
    warehouse,
    isShipmentCompleted,
    hasConfirmedAppointment: hasConfirmedAppointment ?? false,
    carrier: shippingProvider,
    handleCarrierSelectionChange,
    onDateChange,
    handleToggleAppointmentConfirmed,
    handleCarrierEmailBlur,
    handlCarrierEmailFocus,
    onCarrierEmailChange,
    carrierEmail,
    setCarrierEmail,
    isCarrierEmailValid,
    hasCarrierEmailError,
    setHasCarrierEmailError,
    warehouseDataDocksLink,
    existingCarrierEmail,
    updateCarrierEmailInfoToStore,
    checkAndSetCarrierEmailError,
  };
};
